import {Canvas} from '@react-three/fiber'
import React, {Suspense, useEffect, useState} from 'react'
import Z_Model from './Components/Z_Model'
import Z_Sections from './Components/Z_Sections'
import Z_Camera from './Components/Z_Camera'

import * as THREE from 'three'
import {Sky, useProgress} from '@react-three/drei'
import {Perf} from 'r3f-perf'
import {useControls} from 'leva'

const Z_Canvas = ( {
	                   setHasScrolled,
	                   setLoadingProgress,
                   } ) => {
	//region States
	const [ selectedProject, setSelectedProject ] = useState( null )
	//endregion

	const {
		      progress,
		      item,
		      loaded,
		      total,
	      } = useProgress()
	useEffect( () => {
		setLoadingProgress( loaded / 21 )
	}, [ progress ] )

	// Update body overflow
	useEffect( () => {
		document.body.style.overflow = selectedProject !== null ? 'hidden' : ''
	}, [ selectedProject ] )

	//region Debug
	const { showPerf } = useControls( 'Show performances', {
		showPerf: false,
	} )
	//endregion

	return (
			<>
				<Canvas
						gl={ {
							toneMapping:    THREE.LinearToneMapping,
							outputEncoding: THREE.sRGBEncoding,
						} }

						tabIndex={ -1 }
						className={ window[ 'debugActive' ] ? 'debugActive' : '' }
				>
					{ showPerf && <Perf position={ 'top-left' } /> }
					{ window[ 'debugActive' ] &&
					  <>
						  <gridHelper args={ [ 30, 30 * 4 ] } />
						  <gridHelper args={ [ 30, 30, 'red', 'black' ] } />
					  </>
					}

					<Suspense fallback={ null }>
						<Sky
								sunPosition={ [ 10, 30, 10 ] }
								distance={ 40 }
						/>

						<group>
							<Z_Model />

							<Z_Sections/>
						</group>
					</Suspense>

					<Z_Camera setHasScrolled={ setHasScrolled } />
				</Canvas>
			</>
	)
}

export default Z_Canvas
