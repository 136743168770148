import Z_Projects, {ZProject} from './Z_Projects'
import {Html} from '@react-three/drei'
import React, {useEffect, useState} from 'react'
import * as THREE from 'three'
import axios from 'axios'
import {useControls} from 'leva'
import qs from 'qs'

export class ZSection {
	projects: ZProject[]
	position: number[]

	constructor(projects: ZProject[], position: number[]) {
		this.projects = projects
		this.position = position
	}

	getPosition() {
		return new THREE.Vector3(this.position[0], this.position[1], this.position[2])
	}
}

export const Z_Section = ({
							  section,
							  index,
						  }) => {
	return (
		<group name={'section'}
			   position={section.getPosition()}
		>
			<Z_Projects projects={section.projects}
						sectionIndex={index}
			/>
		</group>
	)
}

export default function Z_Sections() {

	const projectsPosition = [
		// Section 1
		{
			x: 0,
			y: 0,
			z: 4.45,
		},
		{
			x: 0,
			y: 0,
			z: 1.6,
		},
		{
			x: 0,
			y: 0,
			z: -1.26,
		},
		{
			x: 0,
			y: 0,
			z: -4.13,
		},
		{
			x: 0,
			y: 0,
			z: -7,
		},
		// Section 2
		{
			x: -6.7,
			y: 0,
			z: 0,
		},
		{
			x: -3.6,
			y: 0,
			z: 0,
		},
		{
			x: 0,
			y: 0,
			z: 0,
		},
		{
			x: 3.35,
			y: 0,
			z: 0,
		},
		{
			x: 6.5,
			y: 0,
			z: 0,
		},
		// Section 3
		{
			x: 0,
			y: 0,
			z: -7,
		},
		{
			x: 0,
			y: 0,
			z: -4.13,
		},
		{
			x: 0,
			y: 0,
			z: -1.26,
		},
		{
			x: 0,
			y: 0,
			z: 1.6,
		},
		{
			x: 0,
			y: 0,
			z: 4.45,
		},
	]
	const sectionsRotation = [
		[0, Math.PI / 2, 0],
		[0, 0, 0],
		[0, -Math.PI / 2, 0],
	]

	//region Sections data
	// Try use memo
	const [sections] = useState([
		new ZSection(
			[],
			[-8.99, 0, 0],
		),
		new ZSection(
			[],
			[0, 0, -9.85],
		),
		new ZSection(
			[],
			[8.99, 0, 0],
		),
	])
	const [loaded, setLoaded] = useState(false)
	//endregion

	//region Load data
	useEffect(() => {
		axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_APP_API_TOKEN}`

		const query = qs.stringify(
			{
				sort: 'rank',
				fields: ['title', 'description', 'behance_link'],
				populate: {
					image: {
						fields: [
							'url',
							'alternativeText',
							'width',
							'height',
							'formats',
						],
					},
				},
			},
			{encodeValuesOnly: true},
		)

		let data = null
		axios.get(`${process.env.REACT_APP_API_LINK}/api/projects?${query}`)
			.then(res => {
				let projects_data = res.data.data

				projects_data.map((project_data, index) => {
					let imageUrl = null
					if (project_data.attributes.image) {
						imageUrl = `${process.env.REACT_APP_API_LINK}${project_data.attributes.image?.data.attributes.url}`
					}

					// Create project
					const sectionIndex = Math.floor(index / 5)
					const project = new ZProject(
						project_data.id,
						project_data.attributes.title,
						project_data.attributes.behance_link,
						imageUrl,
						project_data.attributes.description,
						projectsPosition[index],
						sectionsRotation[sectionIndex],
					)

					// Insert project into the list
					sections[sectionIndex].projects.push(project)
				})
				setLoaded(true)
			})

	}, [])
	//endregion

	//region Contact text
	const title = 'Contactez-nous'
	const subtitle = 'Ovitate velendit veliquat.\nIquis sinullam hil ius.'
	const description = 'Agnimus prorrum istis mintem nis que\nnonsedi gnatur aut ut aperio officium\nfacererspera velecatur si vere pelen-\ndam diti ut andae ent laborestrum'
	//endregion

	//region Debug props
	const introductionDebugProps = useControls('Introduction section', {
			position: [-5.8, 2, 10.7],
			rotation: {
				value: [0, -39 * Math.PI / 32, 0],
				step: Math.PI / 32,
			},
		},
		{collapsed: true},
	)
	const contactDebugProps = useControls('Contact section', {
			position: [5.5, 2, 11],
			rotation: {
				value: [0, 39 * Math.PI / 32, 0],
				step: Math.PI / 32,
			},
		},
		{collapsed: true},
	)
	//endregion

	return (
		<>

			<group
				name="introduction"
				{...introductionDebugProps}
			>
				<Html className="introduction"
					  center
					  transform
					  occlude
					  scale={.25}
					  zIndexRange={[20, 20]}
				>
					<h1>
						We design<br/>
						Beautiful, consistent<br/>
						& remarkable brand
					</h1>
				</Html>
			</group>

			{loaded && <group name="sections"
							  position={[0, 2, 0]}
			>
				{sections.map((section, index) => <Z_Section section={section}
															 key={index}
															 index={index}/>)}
			</group>
			}
			<group
				name="contact"
				{...contactDebugProps}
			>
				<Html className="contact-us"
					  center
					  transform
					  occlude
					  scale={.25}
					  zIndexRange={[20, 20]}
				>
					<h1>CONTACT US!</h1>

					<div className="call-to-action">
						<a className="button"
						   href="https://gax.design/contact">Let's create</a>

						<div className="socials">
							<a href="https://www.behance.net/gaxcreativestudio" target="_blank">
								<img src="/svg/behance.png"
									 alt="Logo behance"/>
							</a>

							<a href="https://www.instagram.com/studio_gax/" target="_blank">
								<img src="/svg/instagram.png"
									 alt="Logo instagram"/>
							</a>

							<a href="https://www.linkedin.com/company/studiogax/" target="_blank">
								<img src="/svg/linkedin.png"
									 alt="Logo Linkedin"/>
							</a>
						</div>
					</div>
				</Html>
			</group>
		</>
	)
}
