import React, {useEffect, useRef, useState} from 'react'
import Z_Canvas from '../Z_Canvas'

import '../App.sass'
import {Leva} from 'leva'
import {LogoLettersGASVG} from "../svg/GAX_Studio_Logo_GA";
import {LogoLetterXSVG} from "../svg/GAX_Studio_Logo_X";
import gsap from "gsap";
import {useProgress} from "@react-three/drei";
const TOTAL_ELEMENTS = 6
const TOTAL_PROJECTS = 15
const Z_App = () => {
	const { active, progress, errors, item, loaded, total } = useProgress()

	//region Refs
	const logoAnimationTL = useRef(gsap.timeline())
	const loadingAnimationTL = useRef(gsap.timeline())
	//endregion

	//region States
	const [ letsGo, setLetsGo ]                   = useState( false )
	const [ hasScrolled, setHasScrolled ]         = useState( false )
	const [ loadingProgress, setLoadingProgress ] = useState( 0 )
	const [ loadingPercent, setLoadingPercent]     = useState( 0 )
	const [ pastLoadingPercent, setPastLoadingPercent]     = useState( 0 )
	//endregion

	//region Effects
	useEffect(() => {
		logoAnimationTL.current.to('.middle .content .letter-x', {
			transformOrigin: 'center',
			rotation: 360,
			duration: 10,
			repeat: -1,
			ease: 'none',
		}, .5)
	}, []);
	// Loading progress changed
	useEffect(() => {
		if (loadingProgress === 1) {
			logoAnimationTL.current.kill()
			logoAnimationTL.current = gsap.timeline()
			logoAnimationTL.current.to('.middle .content .letter-x', {
				transformOrigin: 'center',
				rotation: 180,
				duration: 1,
				ease: 'none',
			})

			loadingAnimationTL.current.kill()
			loadingAnimationTL.current = gsap.timeline()
			loadingAnimationTL.current.to('.loading_bar_inner', {
				width: "100%",
				duration: 1,
				ease: 'none',
			})
				.call(() => {
					handleLetsGo()
				}, [], '>')
				.set('.middle', {
						visibility: 'hidden'
					}, '+=1')
		}
	}, [loadingProgress])

	// Let's go changed
	useEffect( () => {
		document.body.style.overflow = letsGo ? 'auto' : 'hidden'
	}, [ letsGo ] )

	useEffect(() => {
		setPastLoadingPercent(loadingPercent)

		if( loaded <= TOTAL_ELEMENTS) {
			setLoadingPercent(Math.trunc(((loaded / TOTAL_ELEMENTS) * 100 ) * .75))
		} else {
			setLoadingPercent(Math.trunc(75 + ((((loaded - TOTAL_ELEMENTS) / TOTAL_PROJECTS) * 100 ) * .25)))
		}
	}, [loaded]);
	//endregion

	//region Handlers
	const handleLetsGo = () => {
		setLetsGo( true )
	}
	//endregion

	return (
			<>
				<Leva collapsed={ true }
				      hidden={ process.env.NODE_ENV === 'production' } />
				<div className={ `overlay ${ letsGo ? 'middle-hidden' : 'middle-shown' }` }>
					<div className="top">
						<a className="gax-logo"
						   href="https://gax.design">
							<img src="/logo_gax_studio.svg"
							     alt="Logo Gax Studio" />
						</a>

						{ letsGo &&
						  <div className="links">
							  <a href="https://gax.design"
							     className={ 'button' }>gax.design</a>
							  <a href="https://gax.design/contact"
							     className={ 'button' }>Contact us</a>
						  </div>
						}
					</div>

					<div className={ `middle` }>

						<picture className="linear_background" aria-hidden="true">
							<source srcSet="/images/background.webp" type="image/webp"/>
							<img src="/images/background.jpg" alt="Gax colors"/>
						</picture>

						<div className="content">
							<LogoLettersGASVG/>
							<LogoLetterXSVG className={'letter-x'}/>
						</div>

						<div className={'loading_container'}>
							<div className={'loading_bar'}
							>
								<div className={'loading_bar_inner'}
									style={{width: `${loadingPercent}%`}}
								></div>
							</div>
						</div>
					</div>

					<div className={ `bottom ${ !letsGo || hasScrolled ? 'hidden' : '' }` }>
						<div className="text">Scroll</div>

						<svg className={ 'arrow' }
						     width="58"
						     height="88"
						     viewBox="0 0 58 88"
						     xmlns="http://www.w3.org/2000/svg">
							<path d="M29 0.25C29.8288 0.25 30.6237 0.57924 31.2097 1.16529C31.7958 1.75134 32.125 2.5462 32.125 3.375V77.0813L51.7875 57.4125C52.3743 56.8257 53.1701 56.4961 54 56.4961C54.8298 56.4961 55.6257 56.8257 56.2125 57.4125C56.7993 57.9993 57.1289 58.7952 57.1289 59.625C57.1289 60.4548 56.7993 61.2507 56.2125 61.8375L31.2125 86.8375C30.9222 87.1285 30.5774 87.3594 30.1977 87.517C29.818 87.6745 29.411 87.7556 29 87.7556C28.589 87.7556 28.1819 87.6745 27.8023 87.517C27.4226 87.3594 27.0778 87.1285 26.7875 86.8375L1.7875 61.8375C1.20071 61.2507 0.871052 60.4548 0.871052 59.625C0.871052 58.7952 1.20071 57.9993 1.7875 57.4125C2.37429 56.8257 3.17015 56.4961 4 56.4961C4.82985 56.4961 5.62571 56.8257 6.2125 57.4125L25.875 77.0813V3.375C25.875 2.5462 26.2042 1.75134 26.7903 1.16529C27.3763 0.57924 28.1712 0.25 29 0.25Z" />
						</svg>
					</div>
				</div>

				<Z_Canvas setHasScrolled={ setHasScrolled }
				          setLoadingProgress={ setLoadingProgress } />
			</>
	)
}

export default Z_App
